import * as _ from 'lodash'
import { FORMS_APP_DEF_ID } from '../constants'
import { FieldPreset } from '../constants/field-types'
import { siteStore } from './stores/site-store'

export const escapeRegExp = str => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&') //eslint-disable-line no-useless-escape
export const innerText = str => _.trim(str.replace(/\s*<[^>]*>\s*/gm, ''))

export const getValidCollectionId = (formId: string, collectionId: string) => {
  if (!collectionId) return

  const [compId, realCollectionId] = collectionId.split('_')
  return !collectionId.startsWith('comp')
    ? collectionId
    : compId === formId
    ? realCollectionId
    : undefined
}

export const findPlugin = (plugins, pluginId) => _.find(plugins, plugin => plugin.id === pluginId)

export const getFieldType = field => {
  return field.type.split('.')[1]
}

export const replaceMessageInnerText = ($message, replaceFunc: (string) => string): void => {
  const messageText = _.get($message, 'html')
  $message.html = replaceFunc(messageText)
}

const isFieldType = (fieldType: string) => field => field.type === `$w.${fieldType}`

export const isSignatureField = isFieldType('SignatureInput')
export const isUploadButton = isFieldType('UploadButton')
export const isCaptchaField = isFieldType('Captcha')
export const isRadioGroup = isFieldType('RadioButtonGroup')
export const isCheckbox = isFieldType('Checkbox')
export const isNumber = field => isFieldType('TextInput')(field) && field.inputType === 'number'
export const isDatePicker = isFieldType('DatePicker')
export const isTimePicker = isFieldType('TimePicker')

export const toMiliseconds = sec => sec * 1000

const PREVIEW_MODE = 'Preview'

export const isTemplate = wixLocation => !wixLocation.baseUrl
export const isPreviewMode = wixWindow => wixWindow.viewMode === PREVIEW_MODE
export const shouldSendData = wixLocation => !isTemplate(wixLocation)

export const getBaseUrl = () => {
  const urlDirs = siteStore.wixApi.location.baseUrl.split('/')
  let baseUrl = urlDirs.slice(0, urlDirs.length - 1).join('/')

  if (baseUrl === 'https:/' || baseUrl === 'http:/') {
    baseUrl = siteStore.wixApi.location.baseUrl
  }

  return baseUrl
}

export const getInstance = wixSite => wixSite.getAppToken(FORMS_APP_DEF_ID)

export const getFieldValueByCrmType = (fields, crmType) =>
  _.get(findFieldByCrmType(fields, crmType), 'value')

export const findFieldByCrmType = (fields: any[], crmType): any =>
  _.find(fields, field => _.get(field, 'connectionConfig.crmType') === crmType)

export const findFieldByPresetType = (fields: any[], role: FieldPreset): any =>
  _.find(fields, field => _.get(field, 'connectionConfig.fieldType') === role)
